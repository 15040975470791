import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><em parentName="p">{`Metcon Week`}</em></p>
    <p>{`30:00 amrap of:`}</p>
    <p>{`2-Rope Climbs to 15′`}</p>
    <p>{`4-HSPU’s`}</p>
    <p>{`8-Step Ups/Leg (50/35’s)`}</p>
    <p>{`16-Push Ups`}</p>
    <p>{`32-Squats`}</p>
    <h4><strong parentName="h4"><em parentName="strong">{`*`}{`The CrossFit Open starts February 21st!`}</em></strong></h4>
    <h4><strong parentName="h4"><em parentName="strong">{`Register at: Games.CrossFit.com.`}</em></strong></h4>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      